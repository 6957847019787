import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today is “First Friday Hop” around Nulu.  We’re offering any of our
normally scheduled classes for free so invite a friend!`}</em></strong></p>
    <p>{`BB Jump Squats 4×6`}</p>
    <p>{`Glute Ham Raise 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`500M Row Buy In`}</p>
    <p>{`3 rounds of:`}</p>
    <p>{`15-Back Squats (185/125)`}</p>
    <p>{`20-KBS (53/35)`}</p>
    <p>{`25-Situps`}</p>
    <p>{`500M Row Cash out. For time.`}</p>
    <p><strong parentName="p">{`*`}{`This Saturday is Derby day and it’s also our monthly free workout at
9:00 & 10:00am so you can bring a friend this day as well!  We’ll have
just one hour of open gym on Derby day so we’ll close at 12:00.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      